import React from "react";

import { useIntl, Link } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";

function PrivacyPolicy() {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="Younics" />
      <div className="container pt-32 md:pt-52">
        {intl.locale === "sk" ? (
          <div className="bg-gray-purple-a15 p-6 md:p-12 lg:p-16 max-w-5xl mx-auto">
            <h1 className="text-3xl md:text-5xxl lg:text-5xl font-500 pb-8 lg:pb-12">
              Ochrana osobných údajov
            </h1>
            <p className="max-w-2xl leading-loose pb-8 md:pb-12">
              Táto politika ochrany osobných údajov vysvetľuje, ako naša
              organizácia používa osobné údaje, ktoré od vás zhromažďujeme pri
              používaní našich webových stránok.
            </p>
            <h3 className="text-lg md:text-xl pb-4">Témy</h3>
            <ul className="pb-4 pl-6 list-disc">
              <li>Aké údaje zhromažďujeme?</li>
              <li>Ako zhromažďujeme vaše údaje?</li>
              <li>Ako použijeme vaše údaje?</li>
              <li>Ako uchovávame vaše údaje?</li>
              <li>Aké sú vaše práva na ochranu údajov?</li>
              <li>Čo sú to cookies?</li>
              <li>Ako používame cookies?</li>
              <li>Aké typy cookies používame?</li>
              <li>Ako je možné odstrániť cookies?</li>
              <li>Zásady ochrany osobných údajov iných webových stránok</li>
              <li>Zmeny v našich zásadách ochrany osobných údajov</li>
              <li>Ako nás kontaktovať</li>
              <li>Ako kontaktovať príslušné orgány</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Aké údaje zhromažďujeme?
            </h2>

            <p className="pb-1">
              Naša spoločnosť zhromažďuje nasledujúce údaje:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>Osobné identifikačné údaje (meno, e-mailová adresa)</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Ako zhromažďujeme vaše údaje?
            </h2>

            <p className="pb-1">
              Našej spoločnosti priamo poskytujete väčšinu údajov, ktoré
              zhromažďujeme. Zhromažďujeme a spracovávame údaje, keď:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>Odošlete kontaktný formulár.</li>
              <li>Používate alebo prezeráte naše webové stránky.</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Ako použijeme vaše údaje?
            </h2>

            <p className="pb-1">
              Naša spoločnosť zhromažďuje vaše údaje, aby sme mohli:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>
                kontaktovať vás a odpovedať na vaše otázky alebo žiadosti.
              </li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Ako uchovávame vaše údaje?
            </h2>

            <p className="pb-4">
              Naša spoločnosť uchováva nazbierané údaje v nástroji{" "}
              <a
                href="https://analytics.google.com/"
                target="_blank"
                className="underline"
              >
                Google Analytics
              </a>{" "}
              a{" "}
              <a
                href="https://hotjar.com/"
                target="_blank"
                className="underline"
              >
                Hotjar
              </a>
              . Zároveň dostávame e-mail s údajmi, ktoré ste uviedli.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Aké sú vaše práva na ochranu údajov?
            </h2>

            <p className="pb-4">
              Naša spoločnosť sa chce uistiť, že ste si plne vedomí všetkých
              svojich práv na ochranu údajov. Každý užívateľ má právo na:
              <br />
              <br />
              Vymazanie - Máte právo požiadať, aby naša spoločnosť za určitých
              podmienok vymazala vaše osobné údaje.
              <br />
              <br />
              Obmedzenie spracovania - Máte právo požiadať, aby naša spoločnosť
              za určitých podmienok obmedzila spracovanie vašich osobných
              údajov.
              <br />
              <br />
              Namietanie proti spracovaniu - Za určitých podmienok máte právo
              namietať proti spracovaniu vašich osobných údajov v našej
              spoločnosti.
              <br />
              <br />
              Prenosnosť údajov - Máte právo požiadať, aby naša spoločnosť za
              určitých podmienok preniesla údaje, ktoré sme zhromaždili, do inej
              organizácie alebo priamo k vám.
              <br />
              <br />
              Ak podáte žiadosť, musíme vám odpovedať do jedného mesiaca. Ak by
              ste chceli uplatniť ktorékoľvek z týchto práv, kontaktujte nás na
              našom e-maile:
              <br />
              <br />
              <a href="mailto:info@younics.com" className="underline">
                info@younics.com
              </a>
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">Cookies</h2>

            <p className="pb-4">
              Cookies sú textové súbory umiestnené vo vašom počítači na
              zhromažďovanie štandardných informácií z internetového denníka a
              informácií o správaní návštevníkov. Keď navštívite naše webové
              stránky, môžeme od vás automaticky zhromažďovať informácie
              prostredníctvom súborov cookie alebo podobnej technológie.
              <br />
              <br />
              Ďalšie informácie nájdete na adrese{" "}
              <a
                href="https://sk.wikipedia.org/wiki/HTTP_cookie"
                target="_blank"
                className="underline"
              >
                wikipedia.org
              </a>
              .
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Ako používame cookies?
            </h2>

            <p className="pb-1">
              Naša spoločnosť môže používať cookies rôznymi spôsobmi, aby
              zlepšila vašu skúsenosť s použitím našej webovej stránky, vrátane:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>Porozumenie spôsobu používania našej webovej stránky</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Aké typy cookies používame?
            </h2>

            <p className="pb-1">
              Náš web používa množstvo rôznych typov cookies:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>
                Funkčnosť - Naša spoločnosť používa tieto cookies, aby sme vás
                na našej webovej stránke poznali a zapamätali si predtým vybrané
                preferencie. Môže ísť napríklad o jazyk, v ktorom
                uprednostňujete našu webovú stránku, a polohu, v ktorej sa
                nachádzate. Používa sa kombinácia súborov cookie prvej strany a
                tretej strany.
              </li>
              <li>
                Analytika - naša spoločnosť používa tieto cookies na
                zhromažďovanie informácií o vašej návšteve nášho webu, obsahu,
                ktorý ste si prezerali, odkazoch, ktoré ste sledovali, a
                informácií o vašom prehliadači, zariadení a vašej IP adrese.
              </li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Ako je možné odstrániť cookies?
            </h2>

            <p className="pb-1">
              Pri návšteve našej stránky môžete odmietnuť všetky cookies okrem
              funkčných. V nastaveniach prehliadača môžete upraviť nastavenia a
              zabrániť tak ukladaniu cookies. Väčšina prehliadačov obsahuje
              Pomocníka, ktorý Vás prevedie procesom nastavenia.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Zásady ochrany osobných údajov iných webových stránok
            </h2>

            <p className="pb-4">
              Webová stránka našej spoločnosti obsahuje odkazy na iné webové
              stránky. Naše zásady ochrany osobných údajov sa vzťahujú iba na
              naše webové stránky, takže ak kliknete na odkaz na iné webové
              stránky, mali by ste si prečítať ich zásady ochrany osobných
              údajov.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Zmeny v našich zásadách ochrany osobných údajov
            </h2>

            <p className="pb-4">
              Naša spoločnosť pravidelne kontroluje svoje zásady ochrany
              osobných údajov a ukladá všetky aktualizácie na túto webovú
              stránku. Tieto zásady ochrany osobných údajov boli naposledy
              aktualizované 25 April 2019.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Ako nás kontaktovať
            </h2>

            <p className="pb-4">
              Ak máte akékoľvek otázky týkajúce sa ochrany osobných údajov našej
              spoločnosti, údajov, ktoré máme o vás, alebo by ste chceli
              uplatniť jedno z vašich práv na ochranu údajov, neváhajte,
              kontaktujte nás.
              <br />
              <br />
              Pošlite nám e-mail na adresu:{" "}
              <a href="mailto:info@younics.com" className="underline">
                info@younics.com
              </a>
              <br />
              <br />
              alebo napíšte nám na: Ružová dolina 6, 821 08, Bratislava, SK
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Ako kontaktovať príslušný orgán
            </h2>

            <p className="pb-4">
              Ak si želáte nahlásiť sťažnosť alebo ak máte pocit, že naša
              spoločnosť neriešila Váš problém uspokojivým spôsobom môžete
              kontaktovať kanceláriu informačného komisára.
              <br />
              <br />
              Email:{" "}
              <a href="mailto:info@younics.com" className="underline">
                info@younics.com
              </a>
              <br />
              <br />
              Adresa: Ružová dolina 6, 821 08, Bratislava, SK
            </p>
          </div>
        ) : (
          <div className="bg-gray-purple-a15 p-6 md:p-12 lg:p-16 max-w-5xl mx-auto">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-500 pb-8 lg:pb-12">
              Privacy policy
            </h1>
            <p className="max-w-2xl leading-loose pb-8 md:pb-12">
              This privacy policy will explain how our organization uses the
              personal data we collect from you when you use our website.
            </p>
            <h3 className="text-lg md:text-xl pb-4">Topics</h3>

            <ul className="pb-4 pl-6 list-disc">
              <li>What data do we collect?</li>
              <li>How do we collect your data?</li>
              <li>How will we use your data?</li>
              <li>How do we store your data?</li>
              <li>What are your data protection rights?</li>
              <li>What are cookies?</li>
              <li>How do we use cookies?</li>
              <li>What types of cookies do we use?</li>
              <li>How can you remove cookies?</li>
              <li>Privacy policies of other websites</li>
              <li>Changes to our privacy policy</li>
              <li>How to contact us</li>
              <li>How to contact the appropriate authorities</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              What data do we collect?
            </h2>

            <p className="pb-1">Our Company collects the following data:</p>
            <ul className="pb-4 pl-6 list-disc">
              <li>Personal identification information (name, email address)</li>
              <li>
                Any personal information that you'll submit as a part of a job
                application (e.g. a CV)
              </li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              How do we collect your data?
            </h2>

            <p className="pb-1">
              You directly provide Our Company with most of the data we collect.
              We collect data and process data when you:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>Submit a contact us form.</li>
              <li>Use or view our website via your browser’s cookies.</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              How will we use your data?
            </h2>

            <p className="pb-1">
              Our Company collects your data so that we can:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>Contact you and answer your questions or requests.</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              How do we store your data?
            </h2>

            <p className="pb-4">
              Our Company keeps the collected data available at{" "}
              <a
                href="https://analytics.google.com/"
                target="_blank"
                className="underline"
              >
                Google Analytics
              </a>{" "}
              and{" "}
              <a
                href="https://hotjar.com/"
                target="_blank"
                className="underline"
              >
                Hotjar
              </a>
              . We also receive an email with the information you provided.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              What are your data protection rights?
            </h2>

            <p className="pb-4">
              Our Company would like to make sure you are fully aware of all of
              your data protection rights. Every user is entitled to the
              following:
              <br />
              <br />
              The right to erasure – You have the right to request that Our
              Company erase your personal data, under certain conditions.
              <br />
              <br />
              The right to restrict processing – You have the right to request
              that Our Company restrict the processing of your personal data,
              under certain conditions.
              <br />
              <br />
              The right to object to processing – You have the right to object
              to Our Company’s processing of your personal data, under certain
              conditions.
              <br />
              <br />
              The right to data portability – You have the right to request that
              Our Company transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
              <br />
              <br />
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us at
              our email:
              <br />
              <br />
              <a href="mailto:info@younics.com" className="underline">
                info@younics.com
              </a>
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">Cookies</h2>

            <p className="pb-4">
              Cookies are text files placed on your computer to collect standard
              Internet log information and visitor behavior information. When
              you visit our websites, we may collect information from you
              automatically through cookies or similar technology
              <br />
              <br />
              For further information, visit{" "}
              <a
                href="https://en.wikipedia.org/wiki/HTTP_cookie"
                target="_blank"
                className="underline"
              >
                wikipedia.org
              </a>
              .
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              How do we use cookies?
            </h2>

            <p className="pb-1">
              Our Company may use cookies in a range of ways to improve your
              experience on our website, including:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>Understanding how you use our website</li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              What types of cookies do we use?
            </h2>

            <p className="pb-1">
              There are a number of different types of cookies, however, our
              website uses:
            </p>
            <ul className="pb-4 pl-6 list-disc">
              <li>
                Necessary – Our Company uses these cookies so that we recognize
                you on our website and remember your previously selected
                preferences. These could include what language you prefer and
                location you are in. A mix of first-party and third-party
                cookies are used.
              </li>
              <li>
                Analytics – Our Company uses these cookies to collect
                information about your visit to our website, the content you
                viewed, the links you followed and information about your
                browser, device, and your IP address.
              </li>
            </ul>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              How can you remove cookies?
            </h2>

            <p className="pb-1">
              You can choose to opt out of all but the necessary cookies. In the
              settings of the browser, you can change the settings to ensure
              that cookies will be blocked. Most browsers provide you with an
              explanation on how to do this in the so-called ‘help-function’.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Privacy policies of other websites
            </h2>

            <p className="pb-4">
              The Our Company website contains links to other websites. Our
              privacy policy applies only to our website, so if you click on a
              link to another website, you should read their privacy policy.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              Changes to our privacy policy
            </h2>

            <p className="pb-4">
              Our Company keeps its privacy policy under regular review and
              places any updates on this web page. This privacy policy was last
              updated on 25 April 2019.
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">How to contact us</h2>

            <p className="pb-4">
              If you have any questions about Our Company’s privacy policy, the
              data we hold on you, or you would like to exercise one of your
              data protection rights, please do not hesitate to contact us.
              <br />
              <br />
              Email us at:{" "}
              <a href="mailto:info@younics.com" className="underline">
                info@younics.com
              </a>
              <br />
              <br />
              Or write to us at: Ružová dolina 6, 821 08, Bratislava, SK
            </p>

            <h2 className="text-xl md:text-2xl pb-4 pt-8">
              How to contact the appropriate authority
            </h2>

            <p className="pb-4">
              Should you wish to report a complaint or if you feel that Our
              Company has not addressed your concern in a satisfactory manner,
              you may contact the Information Commissioner’s Office.
              <br />
              <br />
              Email:{" "}
              <a href="mailto:info@younics.com" className="underline">
                info@younics.com
              </a>
              <br />
              <br />
              Address: Ružová dolina 6, 821 08, Bratislava, SK
            </p>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
